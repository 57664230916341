const branchRoutes = [
    {
        path: "/branch/list",
        name: "branchList",
        component: () => import("@/modules/branch/list/List.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "view",
            subject: "branch-list"
        }
    },
    {
        path: "/branch/new",
        name: "newBranch",
        component: () => import("@/modules/branch/entry/New.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "create",
            subject: "branch-list"
        }
    },
    {
        path: "/branch/:id/edit",
        name: "editBranch",
        component: () => import("@/modules/branch/entry/Edit.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "edit",
            subject: "branch-list"
        }
    }
]

export default branchRoutes
