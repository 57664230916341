const moduleRoutes = [
    {
        path: "/dashboard/transactions",
        name: "transaction",
        component: () => import("@/modules/dashboard/transactions/Index.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true
        }
    },
    {
        path: "/dashboard/user-frequency",
        name: "userFrequency",
        component: () => import("@/modules/dashboard/user-frequency/Index.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true
        }
    },
    {
        path: "/dashboard/performance",
        name: "performance",
        component: () => import("@/modules/dashboard/performance/Index.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true
        }
    },
    {
        path: "/dashboard/performance/campaign",
        name: "campaignPerformance",
        component: () =>
            import("@/modules/dashboard/campaign-performance/Index.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true
        }
    },
    {
        path: "/dashboard/performance/branch",
        name: "branchPerformance",
        component: () =>
            import("@/modules/dashboard/branch-performance/Index.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true
        }
    },
    {
        path: "/dashboard/:type/file-histories",
        name: "fileHistories",
        component: () =>
            import("@/modules/dashboard/export-file-history/Index.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true
        }
    }
    // {
    //     path: "/dashboard/chart-test",
    //     name: "",
    //     component: () => import("@/modules/dashboard/Index.vue"),
    //     meta: {
    //         layout: "collapse",
    //         requireAuth: true
    //     }
    // }
]

export default moduleRoutes
