const notificationRoutes = [
    {
        path: "/notification",
        name: "notificationList",
        // component: () => import("@/modules/notification/list/List.vue"),
        component: () => import("@/modules/notification/v2/List.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "view",
            subject: "notification-list"
        }
    },
    {
        path: "/notification/:id",
        name: "notificationDetail",
        component: () => import("@/modules/notification/list/Detail.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "view",
            subject: "notification-list"
        }
    },
    {
        path: "/notification/send",
        name: "notificationSend",
        // component: () => import("@/modules/notification/send/Send.vue"),
        component: () =>
            import("@/modules/notification/v2/CustomNotification.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "create",
            subject: "notification-list"
        }
    }
    /*{
        path: "/notification/bulk-import",
        name: "notificationBulkImport",
        component: () => import("@/modules/notification/bulk/SendBulk.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "manage",
            subject: "notifications"
        }
    }*/
]

export default notificationRoutes
