const luckyDrawWebAppRoutes = [
    {
        path: "/lucky-draw-web-app/list",
        name: "luckDrawWebAppList",
        component: () => import("@/modules/lucky-draw-web-app/list/List.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "view",
            subject: "luckydraw-list"
        }
    },
    {
        path: "/lucky-draw/batch",
        component: () =>
            import("@/modules/lucky-draw-web-app/entry/ContainerLuckyDraw.vue"),
        children: [
            {
                path: "/lucky-draw-web-app/batch",
                name: "luckyDrawNewBatch",
                component: () =>
                    import(
                        "@/modules/lucky-draw-web-app/entry/Create/NewBatch.vue"
                    ),
                meta: {
                    layout: "collapse",
                    requireAuth: true,
                    action: "create",
                    subject: "luckydraw-list"
                }
            },
            {
                path: "/lucky-draw-web-app/:id/campaigns",
                name: "CampaignSetup",
                component: () =>
                    import(
                        "@/modules/lucky-draw-web-app/entry/Create/NewCampaign.vue"
                    ),
                meta: {
                    layout: "collapse",
                    requireAuth: true,
                    action: "create",
                    subject: "luckydraw-list"
                }
            },
            {
                path: "/lucky-draw-web-app/:id/customization",
                name: "Customization",
                component: () =>
                    import(
                        "@/modules/lucky-draw-web-app/entry/Create/NewCustomization.vue"
                    ),
                meta: {
                    layout: "collapse",
                    requireAuth: true,
                    action: "create",
                    subject: "luckydraw-list"
                }
            },
            {
                path: "/lucky-draw-web-app/:id/code",
                name: "LuckyDrawCode",
                component: () =>
                    import(
                        "@/modules/lucky-draw-web-app/entry/Create/NewCode.vue"
                    ),
                meta: {
                    layout: "collapse",
                    requireAuth: true,
                    action: "create",
                    subject: "luckydraw-list"
                }
            }
        ],
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "create",
            subject: "luckydraw-list"
        }
    },
    {
        path: "/lucky-draw/batch",
        component: () =>
            import("@/modules/lucky-draw-web-app/entry/ContainerLuckyDraw.vue"),
        children: [
            {
                path: "/lucky-draw-web-app/:id/edit/batch",
                name: "editBatch",
                component: () =>
                    import(
                        "@/modules/lucky-draw-web-app/entry/Edit/EditBatch.vue"
                    ),
                meta: {
                    layout: "collapse",
                    requireAuth: true,
                    action: "edit",
                    subject: "luckydraw-list"
                }
            },
            {
                path: "/lucky-draw-web-app/:id/edit/campaigns",
                name: "editCampaigns",
                component: () =>
                    import(
                        "@/modules/lucky-draw-web-app/entry/Edit/EditCampaign.vue"
                    ),
                meta: {
                    layout: "collapse",
                    requireAuth: true,
                    action: "edit",
                    subject: "luckydraw-list"
                }
            },
            {
                path: "/lucky-draw-web-app/:id/edit/customization",
                name: "editCustomization",
                component: () =>
                    import(
                        "@/modules/lucky-draw-web-app/entry/Edit/EditCustomization.vue"
                    ),
                meta: {
                    layout: "collapse",
                    requireAuth: true,
                    action: "edit",
                    subject: "luckydraw-list"
                }
            },
            {
                path: "/lucky-draw-web-app/:id/edit/code",
                name: "editCode",
                component: () =>
                    import(
                        "@/modules/lucky-draw-web-app/entry/Edit/EditCode.vue"
                    ),
                meta: {
                    layout: "collapse",
                    requireAuth: true,
                    action: "edit",
                    subject: "luckydraw-list"
                }
            }
        ],
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "edit",
            subject: "luckydraw-list"
        }
    },
    {
        path: "/lucky-draw-web-app/batch/:id",
        name: "luckyDrawDetail",
        component: () => import("@/modules/lucky-draw-web-app/list/Detail.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "view",
            subject: "luckydraw-list"
        }
    }
]

export default luckyDrawWebAppRoutes
