const couponRoutes = [
    {
        path: "/coupon/list",
        name: "couponList",
        component: () => import("@/modules/coupon/list/List.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "view",
            subject: "coupon-list"
        }
    },
    {
        path: "/coupon/batch",
        component: () => import("@/modules/coupon/entry/ContainerCoupon.vue"),
        children: [
            {
                path: "/coupon/batch/new",
                name: "newBatch",
                component: () => import("@/modules/coupon/entry/NewBatch.vue"),
                meta: {
                    layout: "collapse",
                    requireAuth: true,
                    action: "create",
                    subject: "coupon-list"
                }
            },
            {
                path: "/coupon/batch/:id/generate",
                name: "generateCoupon",
                component: () => import("@/modules/coupon/entry/NewCoupon.vue"),
                meta: {
                    layout: "collapse",
                    requireAuth: true,
                    action: "create",
                    subject: "coupon-list"
                }
            }
        ]
    },
    {
        path: "/coupon/batch/:id/edit",
        name: "editCoupon",
        component: () => import("@/modules/coupon/entry/EditBatch.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "edit",
            subject: "coupon-list"
        }
    },
    {
        path: "/coupon/batch/:id/show",
        name: "detailCoupon",
        component: () => import("@/modules/coupon/list/Detail.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "view",
            subject: "coupon-list"
        }
    }
]

export default couponRoutes
