export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မင်္ဂလာပါ i18n !!"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "mm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မြန်မာ"])},
  "userList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အသုံးပြုသူစာရင်း"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဒက်ရှ်ဘုတ်"])},
  "modules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မော်ဂျူးများ"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပင်မ"])},
  "subscriptionList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription List"])},
  "notificationModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နိုတီမော်ဂျူး"])},
  "sendNoti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နိုတီပို့"])},
  "sendBulkNoti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နိုတီအများပို့"])},
  "bannerModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banner"])},
  "appConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Configuration"])},
  "rewardsBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reward Balance Visibility"])},
  "luckyDrawWebAppModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucky Draw Web App"])},
  "activityLogsModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Logs"])},
  "dashboardMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Menu"])},
  "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
  "userFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Frequency"])},
  "performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance"])},
  "campaignPerformance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign Performance"])},
  "branchPerformance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch Performance"])}
}