import { Ability } from "@casl/ability"
import { merchantUserAbility } from "./config"

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this

let existingAbility = []
if (localStorage.getItem("userData")) {
    const user = JSON.parse(localStorage.getItem("userData"))
    // console.log(user.user_type)

    let adminAbilities = JSON.parse(localStorage.getItem("userAbility"))

    adminAbilities.push({
        action: "manage",
        name: "manage pocketadmin",
        subject: "pocketadmin"
    })

    existingAbility =
        user.user_type == "Dashboard" ? adminAbilities : merchantUserAbility

    // if (user.email == "digital@pocket.com.mm") {
    //     console.log(user.email)
    //     existingAbility = [
    //         {
    //             action: "send",
    //             subject: "noti"
    //         }
    //     ]
    // }

    // console.log(existingAbility)
}

export default new Ability(existingAbility)
