const moduleRoutes = [
    {
        path: "/",
        name: "userList",
        component: () => import("@/modules/user/list/List.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "view",
            subject: "user-list"
        }
    },
    {
        path: "/merchant-user/new",
        name: "newCashier",
        component: () => import("@/modules/user/entry/New.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "create",
            subject: "user-list"
        }
    },
    {
        path: "/merchant-user/:id/edit",
        name: "editUser",
        component: () => import("@/modules/user/entry/Edit.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "edit",
            subject: "user-list"
        }
    },
    {
        path: "/merchant-user/:id/detail",
        name: "detailUser",
        component: () => import("@/modules/user/list/Detail.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "view",
            subject: "user-list"
        }
    }
]

export default moduleRoutes
