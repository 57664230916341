<template>
    <div class="layout-collapse-menu-header">
        <div class="flex align-items-center justify-content-between">
            <router-link :to="{ name: 'userList' }" class="w-full">
                <div class="flex justify-content-start align-items-center">
                    <img
                        class="menu-collapse-logo"
                        alt="Logo"
                        :src="topbarImage()"
                        style="width: 11.3rem; height: auto"
                    />
                    <!-- <span class="menu-collapse-label ml-3 text-2xl text-bold"
                        >Onenex
                    </span> -->
                </div>
            </router-link>
            <button
                v-if="!mobileMenuActive"
                class="p-link layout-collapse-menu-button layout-collapse-topbar-button ml-0 menu-toggle"
                @click="onMenuToggle"
            >
                <i
                    class="pi pl-3"
                    :class="menuActive ? 'pi-unlock' : 'pi-lock'"
                    style="font-size: 1.5rem; color: var(--text-color)"
                />
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        menuActive: Boolean,
        mobileMenuActive: Boolean
    },
    methods: {
        onMenuToggle(event) {
            // eslint-disable-next-line vue/require-explicit-emits
            this.$emit("menu-toggle", event)
        },
        topbarImage() {
            return this.$appState.darkTheme
                ? require("@/assets/images/pocket_merchant_logo.png")
                : require("@/assets/images/pocket_merchant_logo.png")
        }
    }
}
</script>
