const appConfigurationRoutes = [
    {
        path: "/app-configuration/reward-balances",
        name: "appConfiguration",
        component: () =>
            import("@/modules/app-configuration/reward-balance/List.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "view",
            subject: "reward-balance-list"
        }
    }
]

export default appConfigurationRoutes
