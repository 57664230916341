import { app } from "./main-app"
import PrimeVue from "primevue/config"

app.use(PrimeVue, {
    ripple: false,
    inputStyle: "outlined",
    pt: {
        progressbar: {
            value: {
                style: {
                    "background-color": "#3E83F4"
                }
            }
        }
    }
})
