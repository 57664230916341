const raffleRoutes = [
    {
        path: "/campaign/raffle/list",
        name: "raffleList",
        component: () => import("@/modules/campaign/raffle/list/List.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "view",
            subject: "raffle-list"
        }
    },
    {
        path: "/campaign/raffle/:id/winners",
        name: "raffleWinners",
        component: () => import("@/modules/campaign/raffle/winners/List.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "view",
            subject: "raffle-list"
        }
    }
]

export default raffleRoutes
