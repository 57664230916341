const roleRoutes = [
    {
        path: "/role/list",
        name: "roleList",
        component: () => import("@/modules/role/list/List.vue"),
        meta: {
            subject: "role-list",
            action: "view",
            layout: "collapse"
        }
    },
    {
        path: "/role/new",
        name: "newRole",
        component: () => import("@/modules/role/entry/New.vue"),
        meta: {
            subject: "role-list",
            action: "create",
            layout: "collapse"
        }
    },
    {
        path: "/role/:id/edit",
        name: "editRole",
        component: () => import("@/modules/role/entry/Edit.vue"),
        meta: {
            subject: "role-list",
            action: "edit",
            layout: "collapse"
        }
    }
]

export default roleRoutes
