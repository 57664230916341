const brandRoutes = [
    {
        path: "/brand/list",
        name: "brandList",
        component: () => import("@/modules/brand/list/List.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "view",
            subject: "brand-list"
        }
    },
    {
        path: "/brand/:id/edit",
        name: "editBrand",
        component: () => import("@/modules/brand/entry/Edit.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "edit",
            subject: "brand-list"
        }
    },
    {
        path: "/brand/:id/branch/ranking",
        name: "branchRanking",
        component: () => import("@/modules/brand/branchRanking/List.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "",
            subject: ""
        }
    },
    {
        path: "/brand/:id/menu-items",
        name: "menuItems",
        component: () => import("@/modules/brand/menu-items/list/List.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "",
            subject: ""
        }
    },
    {
        path: "/brand/:id/menu-items/create",
        name: "menuItemCreate",
        component: () => import("@/modules/brand/menu-items/entry/New.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "",
            subject: ""
        }
    },
    {
        path: "/brand/:id/menu-items/:itemId/edit",
        name: "menuItemEdit",
        component: () => import("@/modules/brand/menu-items/entry/Edit.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "",
            subject: ""
        }
    }
]

export default brandRoutes
