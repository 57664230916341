export const superAdminAbility = [
    {
        action: "manage",
        subject: "merchantuser"
    },
    {
        action: "manage",
        subject: "pocketadmin"
    },
    {
        action: "manage",
        subject: "branch"
    },
    {
        action: "manage",
        subject: "pin"
    },
    {
        action: "manage",
        subject: "membership"
    },
    {
        action: "manage",
        subject: "banner"
    },
    {
        action: "manage",
        subject: "coupon"
    },
    {
        action: "send",
        subject: "noti"
    }
]

export const merchantUserAbility = [
    {
        action: "manage",
        subject: "users"
    },
    {
        action: "view",
        subject: "user-list"
    },
    {
        action: "create",
        subject: "user-list"
    },
    {
        action: "edit",
        subject: "user-list"
    },
    {
        action: "delete",
        subject: "user-list"
    },
    {
        action: "export",
        subject: "feedback-list"
    },
    {
        action: "view",
        subject: "luckydraw-list"
    },
    {
        action: "create",
        subject: "luckydraw-list"
    },
    {
        action: "edit",
        subject: "luckydraw-list"
    },
    {
        action: "delete",
        subject: "luckydraw-list"
    },
    {
        action: "manage",
        subject: "campaigns"
    },
    {
        action: "view",
        subject: "raffle-list"
    },
    {
        action: "draw",
        subject: "raffle-list"
    },
    {
        action: "draw",
        subject: "lucky-draw-web-app"
    },
    {
        action: "manage",
        subject: "individual-review"
    }
]

export const _ = undefined
