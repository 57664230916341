const activityLogsRoutes = [
    {
        path: "/activity/logs",
        name: "activityLogs",
        component: () => import("@/modules/activity-logs/list/List.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "",
            subject: ""
        }
    }
]

export default activityLogsRoutes
