const reviewsRoutes = [
    {
        path: "/reviews/brandlist/list",
        name: "reviewBrandList",
        component: () => import("@/modules/reviews/brandlist/List.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "view",
            subject: "feedback-list"
        }
    },
    {
        path: "/reviews/list/:id/:name",
        name: "reviewsList",
        component: () => import("@/modules/reviews/list/List.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "view",
            subject: "feedback-list"
        }
    },
    {
        path: "/reviews/:id/:name/:reviewId",
        name: "reviewsDetail",
        component: () => import("@/modules/reviews/list/Detail.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "view",
            subject: "feedback-list"
        }
    },
    {
        path: "/reviews",
        name: "individualReviewList",
        component: () => import("@/modules/reviews/list/List.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "manage",
            subject: "individual-review"
        }
    },
    {
        path: "/reviews/detail/:id",
        name: "individualReviewsDetail",
        component: () => import("@/modules/reviews/list/Detail.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "view",
            subject: "feedback-list"
        }
    },
    {
        path: "/reviews",
        name: "individualReviewList",
        component: () => import("@/modules/reviews/list/List.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "manage",
            subject: "individual-review"
        }
    },
    {
        path: "/reviews/detail/:reviewId",
        name: "individualReviewsDetail",
        component: () => import("@/modules/reviews/list/Detail.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "manage",
            subject: "individual-review"
        }
    }
]

export default reviewsRoutes
