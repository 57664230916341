const pocketAdminRoutes = [
    {
        path: "/pocket-admin/list",
        name: "pocketAdminList",
        component: () => import("@/modules/pocket-admin/list/List.vue"),
        meta: {
            layout: "collapse",
            action: "view",
            subject: "admin-list",
            requireAuth: true
        }
    },
    {
        path: "/pocket-admin/new",
        name: "newPocketAdmin",
        component: () => import("@/modules/pocket-admin/entry/New.vue"),
        meta: {
            layout: "collapse",
            action: "create",
            subject: "admin-list",
            requireAuth: true
        }
    },
    {
        path: "/pocket-admin/:id/edit",
        name: "editPocketAdmin",
        component: () => import("@/modules/pocket-admin/entry/Edit.vue"),
        meta: {
            layout: "collapse",
            action: "edit",
            subject: "admin-list",
            requireAuth: true
        }
    }
]

export default pocketAdminRoutes
