import api from "@/libs/axios"

const endpoints = {
    login: "/auth/dashboard/login",
    changePassword: "/auth/dashboard/change-password"
}

const authService = {
    login: async (params) => {
        try {
            const result = await api.post(endpoints.login, params)
            return result.data
        } catch {
            return null
        }
    },

    logout: () => {},
    changePassword: async (params) => {
        try {
            const result = await api.post(endpoints.changePassword, params)
            return result.data
        } catch {
            return null
        }
    }
}

export { authService }
