const pinRoutes = [
    {
        path: "/pin/list",
        name: "pinList",
        component: () => import("@/modules/pin/list/List.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "view",
            subject: "pin-list"
        }
    },
    {
        path: "/pin/new",
        name: "newPin",
        component: () => import("@/modules/pin/entry/New.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "create",
            subject: "pin-list"
        }
    },
    {
        path: "/pin/:id/edit",
        name: "editPin",
        component: () => import("@/modules/pin/entry/Edit.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "edit",
            subject: "pin-list"
        }
    }
]

export default pinRoutes
