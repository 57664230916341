import { defineStore } from "pinia"
import { authService } from "./authService"
import ability from "@/libs/acl/ability"
import { merchantUserAbility } from "@/libs/acl/config"

export const useAuthStore = defineStore({
    id: "useAuthStore",
    state: () => ({
        token: localStorage.getItem("userToken") || null,
        userData: localStorage.getItem("userData")
            ? JSON.parse(localStorage.getItem("userData"))
            : null,
        loginResponse: null,
        changePasswordResponse: null
    }),

    getters: {
        getToken(state) {
            return state.token
        },
        getUserData(state) {
            return state.userData
        },
        getLoginResponse(state) {
            return state.loginResponse
        },
        isAuth(state) {
            return state.token ? true : false
        },
        getChangePasswordResponse(state) {
            return state.changePasswordResponse
        }
    },

    actions: {
        async login(params) {
            const response = await authService.login(params)
            this.loginResponse = response
            if (response) {
                this.userData = JSON.stringify(response.data.user)

                //update ability after successfully login
                if (response.data.user.user_type === "Dashboard") {
                    let permissions = response.data.user.allowed_permissions

                    /** 3.2 Issue Privilege Escalation from Low Priv Admin to Super admin **/
                    if (response.data.user.role === "super-admin") {
                        permissions.push({
                            action: "manage",
                            name: "manage pocketadmin",
                            subject: "pocketadmin"
                        })
                    }

                    ability.update(permissions)

                    localStorage.setItem(
                        "userAbility",
                        JSON.stringify(permissions)
                    )
                } else {
                    ability.update(merchantUserAbility)
                    localStorage.setItem(
                        "userAbility",
                        JSON.stringify(merchantUserAbility)
                    )
                }

                localStorage.setItem("userToken", response.data.access_token)
                localStorage.setItem(
                    "userData",
                    JSON.stringify(response.data.user)
                )
                this.token = response.data.access_token
                this.userData = response.data.user
            }
        },
        logout() {
            this.token = null
            this.userData = null
            localStorage.removeItem("userToken")
            localStorage.removeItem("userData")
            localStorage.removeItem("userAbility")
            localStorage.removeItem("startDate")
            localStorage.removeItem("endDate")
        },
        async changePassword(params) {
            this.changePasswordResponse = await authService.changePassword(
                params
            )
        }
    }
})
