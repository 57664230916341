const authRoutes = [
    {
        path: "/login",
        name: "login",
        component: () => import("./login/Login"),
        meta: { redirectIfLoggedIn: true }
    },
    {
        path: "/change-password",
        name: "changePassword",
        component: () => import("./change-password/ChangePassword"),
        meta: {
            layout: "collapse",
            requireAuth: true
        }
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/modules/auth/Index.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true
        }
    }
]

export default authRoutes
