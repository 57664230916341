const membershipRoutes = [
    {
        path: "/membership/list",
        name: "membershipList",
        component: () => import("@/modules/membership/list/List.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "view",
            subject: "membership-list"
        }
    },
    {
        path: "/membership/new",
        name: "newMembership",
        component: () => import("@/modules/membership/entry/New.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "create",
            subject: "membership-list"
        }
    },
    {
        path: "/membership/:id/logic",
        name: "membershipLogic",
        component: () =>
            import("@/modules/membership/entry/Tier/MembershipLogic.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "create",
            subject: "membership-list"
        }
    },
    {
        path: "/membership/:id/edit",
        name: "editMembership",
        component: () => import("@/modules/membership/entry/Edit.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "edit",
            subject: "membership-list"
        }
    },
    {
        path: "/membership/:id/detail",
        name: "detailMembership",
        component: () => import("@/modules/membership/list/Detail.vue"),
        meta: {
            layout: "collapse",
            requireAuth: true,
            action: "view",
            subject: "membership-list"
        }
    }
]

export default membershipRoutes
