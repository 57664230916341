<template>
    <!-- <Menu ref="userMenu" :model="profileMenuItems" :popup="true" /> -->
    <div class="relative">
        <div
            class="flex justify-content-center align-items-center cursor-pointer ml-3"
            id="profile-dropdown"
            @click="togglePanel"
        >
            <Avatar
                :image="require('@/assets/images/Profile.svg')"
                size="large"
                shape="circle"
            />
            <div class="user-menu ml-4 hidden lg:block">
                <div class="font-bold">{{ user.name }}</div>
                <div class="text-sm mt-1">{{ user.role }}</div>
            </div>
            <i
                class="pi pi-angle-down ml-2 hidden lg:block"
                style="color: var(--primary-color)"
            ></i>
        </div>
        <Card v-if="isOpen" class="profile-card">
            <template #content>
                <div
                    class="p-3 flex align-items-center profile-items"
                    v-for="item of profileMenuItems"
                    :key="item"
                    @click="item.command"
                >
                    <i :class="item.icon" class="mr-3"></i>
                    {{ item.label }}
                </div>
            </template>
        </Card>
    </div>
</template>
<script>
import { ref, defineComponent, onMounted, onUnmounted } from "vue"

import { useAuthStore } from "@/modules/auth/authStore"
import { useRouter } from "vue-router"
import { useConfirm } from "primevue/useconfirm"

import Avatar from "primevue/avatar"
import Card from "primevue/card"
// import Button from "primevue/button"
// import Divider from "primevue/divider"
// import OverlayPanel from "primevue/overlaypanel"
// import ConfirmDialog from "primevue/confirmdialog"

export default defineComponent({
    name: "AppUserProfile",
    components: {
        Avatar,
        Card
        // Button,
        // Divider
        //  OverlayPanel,
        // ConfirmDialog
    },
    setup() {
        const authStore = useAuthStore()
        const router = useRouter()
        const userMenu = ref()
        const confirm = useConfirm()

        const user = authStore.getUserData

        const isOpen = ref(false)

        const profileMenuItems = ref([
            // {
            //     label: "User Profile",
            //     icon: "pi pi-user"
            // },
            // {
            //     separator: true
            // },
            {
                label: "Logout",
                icon: "pi pi-sign-out",
                command: () => {
                    userLogout()
                }
            }
        ])

        const togglePanel = () => {
            isOpen.value = !isOpen.value
        }

        // const handleScroll = () => {
        //     userMenu.value.hide()
        // }

        onMounted(() => {
            if (user.role == "super-admin") {
                profileMenuItems.value.unshift({
                    label: "Change Password",
                    icon: "pi pi-lock",
                    command: () => {
                        changePassword()
                    }
                })
            }
            // window.addEventListener("scroll", handleScroll)
            window.addEventListener("click", function (e) {
                if (this.document.getElementById("profile-dropdown")) {
                    if (
                        !this.document
                            .getElementById("profile-dropdown")
                            .contains(e.target)
                    ) {
                        // close dropdown when clicked outside
                        if (isOpen.value == true) {
                            isOpen.value = false
                        }
                    }
                }
            })
        })

        onUnmounted(() => {
            // window.addEventListener("scroll", handleScroll)
        })

        const showConfirmDialog = () => {
            confirm.require({
                message: "Are you sure?",
                icon: "pi pi-exclamation-triangle",
                acceptLabel: "Sign out",
                rejectLabel: "Cancel",
                acceptClass: "p-button-danger",
                rejectClass: "p-button-secondary p-button-text",

                accept: () => {
                    userLogout()
                },
                reject: () => {
                    //callback to execute when corporate rejects the action
                },
                onHide: () => {
                    //Callback to execute when dialog is hidden
                }
            })
        }

        const userLogout = () => {
            authStore.logout()
            router.push({ name: "login" })
        }

        const changePassword = () => {
            router.push({
                name: "changePassword"
            })
        }

        return {
            user,
            userMenu,
            profileMenuItems,
            togglePanel,
            userLogout,
            showConfirmDialog,
            isOpen
        }
    }
})
</script>
<style>
.user-menu {
    min-width: 100px;
}
.p-overlaypanel .p-overlaypanel-content {
    padding: 0 !important;
}
.p-dialog-footer {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
.profile-card {
    position: absolute;
    left: 1rem;
    transform-origin: center top;
    min-width: 184px;
    z-index: 1001;
    cursor: pointer;
}
.profile-card .p-card-content,
.profile-card .p-card-body {
    padding: 0;
}
.profile-items:hover {
    background: #e9ecef !important;
}
</style>
